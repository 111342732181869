.portfolio-summary {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-sm);
  background-color: #dbc178;
  color: black;
  padding: var(--spacing-md);
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  width: 100%;
}

.portfolio-item {
  text-align: center;
  padding: var(--spacing-sm);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.portfolio-item h3 {
  font-size: 0.9rem;
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
}

.portfolio-item p {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5rem 0 0;
}

@media (min-width: 768px) {
  .portfolio-summary {
    grid-template-columns: repeat(4, 1fr);
  }

  .portfolio-item h3 {
    font-size: 1rem;
  }

  .portfolio-item p {
    font-size: 1.7rem;
  }
}
