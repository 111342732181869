.google-sign-in-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #3c4043;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  cursor: pointer;
  transition: box-shadow 0.2s, background-color 0.2s;
  box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3);
}

.google-sign-in-button:hover {
  background-color: #f7f8f8;
  box-shadow: 0 1px 3px rgba(60, 64, 67, 0.4);
}

.google-sign-in-button:active {
  background-color: #e8e9ea;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.2);
}

.google-sign-in-button:focus {
  outline: none;
  box-shadow: 0 0 3px 2px rgba(66, 133, 244, 0.6);
}

.google-sign-in-button:disabled {
  cursor: not-allowed;
  background-color: #f8f9fa;
  color: #dadce0;
  border-color: #dadce0;
  box-shadow: none;
}

.google-logo {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
