@import '../styles/variables.css';

.home {
  background: var(--surface);
  width: calc(100% - var(--spacing-md) * 2);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 190px);
  padding: var(--spacing-md) var(--spacing-md);
  align-items: center;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .home {
    margin: 64px auto 0;
    padding: var(--spacing-lg) var(--spacing-md);
    width: 100%;
  }
}

@media (max-width: 576px) {
  .home {
    margin: 64px auto 0;
    padding: var(--spacing-lg) var(--spacing-md);
    width: 100%;
  }
}

.index-drawdowns {
  margin-bottom: var(--spacing-lg);
  width: 100%;
}

.stock-drawdown-container {
  display: grid;
  gap: var(--spacing-sm);
  width: 100%;
  margin-top: var(--spacing-md);
  box-sizing: border-box;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 var(--spacing-sm);
}

@media (min-width: 992px) {
  .stock-drawdown-container {
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacing-md);
    padding: 0;
  }
}

.page-header {
  width: 100%;
  margin-bottom: var(--spacing-lg);
}

.header-with-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  width: 100%;
  padding-bottom: var(--spacing-sm);
  border-bottom: 2px solid var(--secondary);
}

.h-1 {
  color: var(--text-primary);
  font-family: 'Inter', -apple-system, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  padding-bottom: var(--spacing-sm);
  border-bottom: 2px solid var(--secondary);
}

/* Remove border when h-1 is inside header-with-link */
.header-with-link .h-1 {
  padding-bottom: 0;
  border-bottom: none;
}

@media (max-width: 576px) {
  .page-header {
    margin-bottom: var(--spacing-md);
  }

  .h-1 {
    font-size: 1.5rem;
    padding-bottom: var(--spacing-sm);
  }
}

.my-portfolio {
  width: 100%;
  margin-bottom: var(--spacing-xl);
}

.portfolio-container {
  background: var(--surface);
  border-radius: 8px;
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 576px) {
  .portfolio-container {
    padding: var(--spacing-sm);
  }
}

.home-search-wrapper {
  max-width: 600px;
  margin: var(--spacing-lg) auto 0;
  padding: 0 var(--spacing-sm);
  position: relative;
  display: flex;
  align-items: center;
}

.home-search-input {
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) 2.5rem;
  font-size: 1rem;
  border: 1px solid var(--secondary);
  border-radius: 8px;
  background: var(--surface);
  color: var(--text-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.home-search-icon {
  position: absolute;
  left: calc(var(--spacing-md) + 0.75rem);
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  color: var(--text-secondary);
  pointer-events: none;
}

.home-search-input:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.home-search-input::placeholder {
  color: var(--text-secondary);
}


.status-message {
  background-color: var(--background-secondary);
  border-radius: 6px;
  padding: var(--spacing-md);
  text-align: center;
  color: var(--text-secondary);
  font-size: 1.1rem;
  margin-top: var(--spacing-md);
  border: 1px solid var(--border);
}

.watchlist-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: var(--surface);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--text-primary);
  border-radius: 8px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.watchlist-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  color: var(--text-primary);
}

.watchlist-link .arrow-icon {
  width: 16px;
  height: 16px;
  transition: transform 0.2s ease;
  opacity: 0.8;
}

.watchlist-link:hover .arrow-icon {
  transform: translateX(4px);
  opacity: 1;
} 
