.stock-drawdown {
  background: var(--surface);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.stock-drawdown:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.stock-drawdown:focus {
  outline: 2px solid var(--primary);
  outline-offset: 2px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-xs);
}

.header h2 {
  color: var(--text-primary);
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
}

.header .ticker {
  color: var(--text-secondary);
  font-size: 0.8rem;
  font-weight: normal;
  text-align: right;
}

.drawdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-xs);
}

.drawdown .value {
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-primary);
}

.drawdown .label {
  font-size: 0.75rem;
  color: var(--text-secondary);
}

.metrics {
  border-top: 1px solid var(--secondary);
  padding-top: var(--spacing-xs);
}

.metrics p {
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-xs) 0;
  font-size: 0.8rem;
}

.metrics .label {
  font-weight: 500;
  color: var(--text-secondary);
}

.metrics .value {
  font-weight: 600;
  color: var(--text-primary);
}

.ticker-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.after-hours-icon {
  width: 14px;
  height: 14px;
  opacity: 0.7;
  filter: var(--icon-filter);
}

@media (min-width: 992px) {
  .stock-drawdown {
    padding: var(--spacing-md);
    font-size: 1rem;
  }

  .stock-drawdown h2 {
    font-size: 1.1rem;
  }

  .stock-drawdown i {
    font-size: 0.875rem;
  }

  .metrics p {
    font-size: 0.9rem;
  }

  .drawdown {
    font-size: 1.3rem;
  }

  .after-hours-icon {
    width: 16px;
    height: 16px;
  }
}


