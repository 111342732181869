:root {
  /* Color System */
  --primary: #1a1a1a;
  --secondary: #333333;
  --accent: #E74C3C;
  --success: #22c55e;  /* Bright green for positive changes */
  --error: #ef4444;    /* Bright red for negative changes */
  --warning: #F1C40F;
  --background: #ffffff;
  --surface: #ffffff;
  --text-primary: #000000;
  --text-secondary: #000000;
  
  /* Spacing System */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  
  /* Shadows */
  --shadow-sm: none;
  --shadow-md: none;
  --shadow-lg: none;
} 