.app-container {
  display: flex;
}

.sidenav {
  position: fixed;
  background-color: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 25;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease-in-out;
}

/* Desktop styles */
@media (min-width: 768px) {
  .sidenav {
    width: 180px;
    padding: 1rem 0;
    height: 100vh;
    top: 70px;
    background: linear-gradient(to right, #ffffff, #f8f8f8);
    transform: translateX(0);
  }

  main {
    margin-left: 180px;
    margin-top: 70px;
    padding: 1rem;
    width: calc(100vw - 180px);
  }
}

/* Mobile styles */
@media (max-width: 767px) {
  .sidenav {
    top: 70px;
    width: 250px;
    height: calc(100vh - 70px);
    padding: 1rem 0;
    transform: translateX(-100%);
    background-color: #ffffff;
  }

  .sidenav.open {
    transform: translateX(0);
  }

  .sidenav ul {
    flex-direction: column;
    padding: 1rem;
  }

  .sidenav ul li {
    margin: 1rem 0;
  }

  main {
    margin-left: 0;
    width: 100%;
  }
}

.sidenav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidenav ul li {
  margin: 8px 0;
}

.sidenav ul li a {
  text-decoration: none;
  color: var(--text-primary);
  font-size: 1.1rem;
  font-weight: 500;
  display: block;
  padding: 4px 16px;
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
}

.sidenav ul li a:hover {
  background-color: rgba(219, 193, 120, 0.1);
  border-left: 3px solid #dbc178;
  padding-left: 20px;
}
