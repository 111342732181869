.drawdown-chart {
  margin-top: var(--spacing-sm);
  padding: var(--spacing-md);
  background: var(--surface);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.time-range-selector {
  display: flex;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
}

.range-button {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--secondary);
  background: var(--surface);
  color: var(--text-primary);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.range-button.active {
  background: var(--primary);
  color: var(--surface);
  border-color: var(--primary);
}

.chart-container {
  height: 400px;
  position: relative;
}

.chart-watermark {
  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  pointer-events: none;
  z-index: 1;
  width: 200px;
  height: auto;
}

@media (max-width: 768px) {
  .chart-container {
    height: 300px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--text-secondary);
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--error);
  padding: 1rem;
  text-align: center;
}

.drawdown-summary {
  padding: var(--spacing-md);
  background: var(--surface);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.summary-stat {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
  margin: var(--spacing-xs) 0;
}

.stat-label {
  font-size: 1rem;
  color: var(--text-primary);
}

.stat-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(220, 53, 69);
} 