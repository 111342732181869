.portfolio-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--spacing-sm);
}

@media (min-width: 992px) {
  .portfolio-container {
    padding: 0;
  }
}
