/* PositionTable.css */

.position-table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: var(--spacing-md);
  background: var(--surface);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.position-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.position-table th {
  background: #ebebeb;
  color: var(--text-primary);
  font-weight: 600;
  text-align: right;
  padding: var(--spacing-sm);
  white-space: nowrap;
  border-bottom: 1px solid var(--secondary);
}

.position-table th:first-child {
  text-align: left;
}

.position-table td {
  padding: var(--spacing-sm);
  text-align: right;
  border-bottom: 1px solid var(--secondary);
}

.position-table td:first-child {
  text-align: left;
  font-weight: 500;
}

.position-table tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

@media (max-width: 768px) {
  .position-table {
    font-size: 0.8rem;
  }
  
  .position-table th,
  .position-table td {
    padding: var(--spacing-xs) var(--spacing-sm);
  }
}
