.search-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 17px auto;
  width: 400px;
}

.search-bar {
  width: 100%;
  padding: 6px 35px 6px 40px;
  border: 2px solid rgba(219, 193, 120, 0.5);
  border-radius: 4px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.18);
  color: white;
  transition: all 0.3s ease;
}

.search-bar::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.search-bar:focus {
  outline: none;
  border-color: #dbc178;
  background-color: rgba(255, 255, 255, 0.12);
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(255, 255, 255, 0.6);
  width: 16px;
  height: 16px;
}

@media (max-width: 767px) {
  .search-container {
    width: calc(100vw - 140px);  /* Accounts for hamburger, logo, and right margin */
    margin: 17px 10px;
  }

  .search-bar {
    font-size: 14px;
    padding: 6px 30px 6px 35px;
  }

  .search-bar::placeholder {
    font-size: 14px;
  }
} 