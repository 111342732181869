@font-face {
  font-family: "Markazi Text Bold";
  src: url("../fonts/MarkaziText-VariableFont_wght.ttf") format("truetype");
}

.navbar {
  color: black;
  display: flex;
  flex-direction: row;
  width: 100%;
  z-index: 20;
  position: fixed;
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 20px;
  min-width: 250px;
}
.logo-img {
  width: 250px;
  height: auto;
}

.container {
  background-color: black;
  border: none;
  box-shadow: 0px 1px 4px rgb(56, 56, 56);
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 25px;
}

.auth-links {
  display: flex;
  margin: 17px;
  padding-right: 20px;
  gap: 0.5rem;
  min-width: fit-content;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 17px 0;
  width: 300px;
  flex: 0 0 auto;
}

.login-button,
.signup-button {
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* Login Button - White text, no background */
.login-button {
  background: none;
  color: white;
}

/* Add a subtle hover effect for the login button */
.login-button:hover {
  color: #ccc; /* Slightly lighter white */
}

/* Signup Button - Black text on gold background with emphasis */
.signup-button {
  background-color: #dbc178;
  color: black;
  font-weight: bold;
}

/* Add a hover effect for the signup button */
.signup-button:hover {
  background-color: darkgoldenrod;
}

.mobile-logo {
  display: none;
  width: 40px;
  height: auto;
}

.desktop-logo {
  display: block;
  width: 250px;
  height: auto;
}

@media (max-width: 767px) {
  .mobile-logo {
    display: block;
  }
  
  .desktop-logo {
    display: none;
  }
  
  .logo {
    min-width: auto;
    margin-right: 15px;
    margin-left: 10px;
  }
  
  .container {
    padding: 0 10px;
  }
  
  .search-container {
    width: 300px;
  }
  
  .auth-links {
    display: none;
  }
}
