.mobile-menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 30;
}

.mobile-menu-toggle span {
  width: 30px;
  height: 2px;
  background: white;
  border-radius: 10px;
  transition: all 0.3s ease;
  position: relative;
}

@media (max-width: 767px) {
  .mobile-menu-toggle {
    display: flex;
  }

  .mobile-menu-toggle.open span:first-child {
    transform: rotate(45deg);
    top: 9px;
  }

  .mobile-menu-toggle.open span:nth-child(2) {
    opacity: 0;
  }

  .mobile-menu-toggle.open span:last-child {
    transform: rotate(-45deg);
    top: -9px;
  }
} 