.portfolio-overlay {
  position: relative;
  background: #1a1a1a;
  border-radius: 8px;
  padding: var(--spacing-md);
  margin: 0 0 var(--spacing-md) 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.portfolio-overlay:hover {
  transform: translateY(-2px);
}

.portfolio-overlay-content {
  text-align: center;
  color: white;
}

.portfolio-overlay-content p {
  margin: 0;
  font-size: 1rem;
  opacity: 0.9;
}
