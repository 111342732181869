.page-container {
  background: var(--surface);
  width: calc(100% - var(--spacing-md) * 2);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 190px);
  padding: var(--spacing-md) var(--spacing-md);
  align-items: center;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .page-container {
    margin: 64px auto 0;
    padding: var(--spacing-lg) var(--spacing-md);
    width: 100%;
  }
}

@media (max-width: 576px) {
  .page-container {
    margin: 64px auto 0;
    padding: var(--spacing-lg) var(--spacing-md);
    width: 100%;
  }
} 