.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* Modal header */
.modal-content h2 {
  margin-top: 0;
  font-size: 1.5em;
}

/* Input field styling */
.modal-content input[type="text"] {
  margin: 0.75rem 0;
  padding: 0.5rem;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  box-sizing: border-box;
}

/* Button styling */
.modal-content button {
  padding: 10px 20px;
  font-size: 1em;
  margin: 10px 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Save button */
.modal-content button:first-of-type {
  background-color: #4caf50; /* Green */
  color: white;
}

/* Cancel button */
.modal-content button:last-of-type {
  background-color: #f44336; /* Red */
  color: white;
}

/* Button hover effect */
.modal-content button:hover {
  opacity: 0.9;
}
