.stock-detail {
  background: var(--surface);
  width: calc(100% - var(--spacing-md) * 2);
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md) var(--spacing-md);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .stock-detail {
    margin: 64px auto 0;
    padding: var(--spacing-lg) var(--spacing-md);
    width: 100%;
  }
}

@media (max-width: 576px) {
  .stock-detail {
    margin: 68px auto 0;
    padding: var(--spacing-lg) var(--spacing-lg);
    width: 100%;
  }
}

.company-header {
  margin-bottom: var(--spacing-sm);
  border-bottom: 2px solid var(--secondary);
  padding-bottom: var(--spacing-md);
}

.name-symbol h1 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-primary);
  margin: 0;
}

.name-symbol h1 span {
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.price-info {
  margin-top: var(--spacing-sm);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.price-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.price-label {
  font-size: 0.75rem;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.after-hours-label {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
}

.after-hours-icon {
  width: 12px;
  height: 12px;
  opacity: 0.7;
  filter: var(--icon-filter);
  margin-top: 1px;
}

.price-details {
  display: flex;
  align-items: baseline;
  gap: var(--spacing-sm);
}

.current-price {
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-primary);
  line-height: 1;
}

.price-change {
  font-size: 1.1rem;
  font-weight: 500;
}

.company-header h2 {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin: var(--spacing-xs) 0 0 0;
}

.symbol-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xl);
  text-align: center;
  min-height: 50vh;
}

.symbol-not-found h1 {
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.symbol-not-found p {
  color: var(--text-secondary);
  margin-bottom: var(--spacing-lg);
}

.home-link {
  padding: var(--spacing-sm) var(--spacing-md);
  background: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: opacity 0.2s ease;
}

.home-link:hover {
  opacity: 0.9;
}

.stock-actions {
  margin: var(--spacing-sm) 0;
  display: flex;
  gap: var(--spacing-md);
}

.watchlist-button {
  padding: var(--spacing-sm) var(--spacing-md);
  background: var(--surface);
  border: 1px solid var(--primary);
  color: var(--text-primary);
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.watchlist-button:hover {
  background: var(--primary);
  color: white;
}

.add-icon {
  color: var(--primary);
  font-size: 1.2rem;
  font-weight: bold;
}

.watching-icon {
  color: var(--success);
  font-size: 1.2rem;
}

.stock-metrics {
  background: var(--surface);
  border-radius: 8px;
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
  border: 1px solid var(--border);
}

.stock-metrics h2 {
  font-size: 1.2rem;
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-md);
}

.metric-card {
  background: var(--background);
  padding: var(--spacing-md);
  border-radius: 6px;
  border: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.metric-label {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.metric-value {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-primary);
}

.drawdown-summary {
  background: var(--surface);
  border-radius: 8px;
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
  border: 1px solid var(--border);
}

.metric-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-md);
}

.metric {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.metric .label {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.metric .value {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-primary);
}

.after-hours {
  padding-top: var(--spacing-xs);
  border-top: 1px solid var(--border);
  margin-top: var(--spacing-xs);
  opacity: 0.9;
}

.after-hours-price {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  line-height: 1;
}

.after-hours-change {
  font-size: 1rem;
  font-weight: 500;
} 