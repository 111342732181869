@font-face {
  font-family: "Markazi Text Bold";
  src: url("../fonts/MarkaziText-VariableFont_wght.ttf") format("truetype");
}

.frame {
  background-color: #1a1a1a;
  color: #fcfaf3;
  padding: 1rem;
  width: 100%;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  z-index: 30;
  box-sizing: border-box;
}

.join_investors {
  color: #fcfaf3;
  font-family: "Markazi Text Bold", Helvetica;
  font-size: 1rem;
  font-weight: 350;
  text-align: center;
  max-width: 800px;
  line-height: 1.3;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.join_investors p {
  margin: 0.25rem 0;
}

.join_investors a {
  text-decoration: none;
  color: #dbc178;
  transition: color 0.2s ease;
}

.join_investors a:hover {
  color: #e9d4a7;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .join_investors {
    font-size: 0.9rem;
    padding: 0 1rem;
    margin: 0 auto;
    width: 100%;
  }
  
  .frame {
    padding: 1rem 0;
  }
}
