@import '../styles/layout.css';

.position-table-container {
  width: 100%;
  margin-bottom: var(--spacing-lg);
}

.position-table-container h2 {
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: var(--spacing-md);
}

.position-table {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
}

@media (max-width: 767px) {
  .position-table-container h2 {
    font-size: 1.2rem;
    margin-bottom: var(--spacing-sm);
  }

  .position-table th,
  .position-table td {
    padding: 8px 4px;
    font-size: 14px;
  }
}
