.add-position {
  padding: var(--spacing-sm);
  background: var(--surface);
  border-top: 1px solid var(--secondary);
}

.add-position h4 {
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 var(--spacing-md) 0;
}

.input-container {
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
}

.input-container input {
  padding: var(--spacing-xs) var(--spacing-sm);
  border: 1px solid var(--secondary);
  border-radius: 4px;
  font-size: 0.9rem;
  color: var(--text-primary);
  background: var(--surface);
  height: 32px;
}

.symbol-input {
  width: 100px;
}

.quantity-input {
  width: 80px;
}

.add-button {
  padding: 0;
  width: 32px;
  height: 32px;
  background: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-button:hover {
  opacity: 0.9;
}

.error-message {
  color: var(--error);
  font-size: 0.8rem;
  margin-top: var(--spacing-xs);
}

.success-message {
  color: var(--success);
  font-size: 0.8rem;
  margin-top: var(--spacing-xs);
}

@media (max-width: 768px) {
  .input-group {
    grid-template-columns: 1fr;
  }
  
  .add-position {
    padding: var(--spacing-sm);
  }
} 