.sign-up-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sign-up-modal-content {
  background: var(--surface);
  padding: var(--spacing-lg);
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  position: relative;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: var(--spacing-sm);
  right: var(--spacing-sm);
  cursor: pointer;
  color: var(--text-secondary);
  transition: color 0.2s ease;
}

.close-button:hover {
  color: var(--text-primary);
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: var(--spacing-md);
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.sign-up-form input {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--secondary);
  border-radius: 4px;
  font-size: 0.9rem;
  color: var(--text-primary);
  background: var(--surface);
  box-sizing: border-box;
}

.sign-up-form input:focus {
  outline: 2px solid var(--primary);
  outline-offset: -1px;
}

.sign-up-form button {
  padding: var(--spacing-sm);
  background: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: opacity 0.2s ease;
  margin-top: var(--spacing-sm);
}

.sign-up-form button:hover {
  opacity: 0.9;
}

.error-text {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: var(--spacing-xs);
}

.fine-print {
  color: var(--text-secondary);
  font-size: 0.8rem;
  margin-top: var(--spacing-md);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--secondary);
  margin: var(--spacing-md) 0;
}

.switch-auth-mode {
  background: none;
  border: none;
  color: var(--primary);
  font-size: 0.9rem;
  cursor: pointer;
  padding: var(--spacing-sm);
  margin-top: var(--spacing-sm);
  text-decoration: underline;
  transition: opacity 0.2s ease;
}

.switch-auth-mode:hover {
  opacity: 0.8;
}
