/* Modal backdrop styling */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal box styling */
.modal {
  background: var(--surface);
  padding: var(--spacing-lg);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  text-align: center;
}

/* Input field styling */
.modal input {
  width: 100%;
  padding: var(--spacing-sm);
  margin: var(--spacing-sm) 0;
  border: 1px solid var(--secondary);
  border-radius: 4px;
  font-size: 1rem;
  color: var(--text-primary);
  background: var(--surface);
  box-sizing: border-box;
}

/* Button group styling */
.button-group {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-sm);
  margin-top: var(--spacing-md);
}

.button-group button {
  flex: 1;
  padding: var(--spacing-sm);
  border-radius: 4px;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button-group button:first-child {
  background: var(--primary);
  color: white;
}

.button-group button:last-child {
  background: var(--secondary);
  color: var(--text-primary);
}

/* Error and message styles */
.error {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: var(--spacing-xs);
}

.message {
  color: #28a745;
  font-size: 0.9rem;
  margin-top: var(--spacing-xs);
}

.add-account-button {
  display: block;
  width: 100%;
  padding: var(--spacing-md);
  margin-top: var(--spacing-md);
  background: var(--surface);
  border: 2px dashed var(--secondary);
  border-radius: 8px;
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-account-button:hover {
  background: var(--secondary);
  border-color: var(--primary);
  color: var(--text-primary);
}

.add-account-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .add-account-button {
    padding: var(--spacing-sm);
    font-size: 0.9rem;
  }
}
