.account-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
  margin-top: var(--spacing-md);
}

.account-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.account-container {
  background: var(--surface);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.account-header {
  background-color: #ebebeb;
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-header h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
}

.edit-button {
  background: none;
  border: none;
  padding: var(--spacing-xs);
  cursor: pointer;
  color: var(--text-secondary);
  transition: color 0.2s ease;
}

.edit-button:hover {
  color: var(--text-primary);
}

.edit-square {
  width: 16px;
  height: 16px;
}

@media (min-width: 768px) {
  .account-header h3 {
    font-size: 1.2rem;
  }
  
  .edit-square {
    width: 18px;
    height: 18px;
  }
}
